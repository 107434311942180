import request from "@/request/request-utils";

export default {
  // 教程列表
  tutorials: (params) =>
    request.getAction("/api/Seller/GetTutorialList", params),
  // 店铺列表
  shops: (params) =>
    request.getAction("/api/Seller/GetTaoBaoShopPageList", params),
  // 保存店铺
  saveShop: (data) => request.postJson("/api/Seller/SaveTaoBaoShop", data),
  // 删除店铺
  deleteShop: (params) =>
    request.getAction("/api/Seller/DeleteTaoBaoShop", params),
  // 设置店铺额度
  setShopQuota: (params) =>
    request.getAction("/api/Seller/SetTaoBaoShopHuoFanQuota", params),
  // 商品列表
  products: (params) =>
    request.getAction("/api/Seller/GetTaoBaoProductPageList", params),
  // 自动添加商品
  addAutoProduct: (params) =>
    request.getAction("/api/Seller/SaveTaoBaoProductAuto", params),
  // 自动批量添加商品
  addBatchProduct: (data) =>
    request.postJson("/api/Seller/AddTaoBaoProductAuto", data),
  // 保存商品
  saveProduct: (data) =>
    request.postData("/api/Seller/SaveTaoBaoProduct", data),
  // 删除商品
  deleteProduct: (params) =>
    request.getAction("/api/Seller/DeleteTaoBaoProduct", params),
  // 获取商品信息
  productInfo: (params) =>
    request.getAction("/api/Seller/GetTaoBaoProduct", params),
  // 获取多商品信息
  productInfos: (params) =>
    request.getAction("/api/Seller/GetTaoBaoProductList", params),
  // 下属商家列表
  subordinateSellers: (params) =>
    request.getAction("/api/Seller/GetSellerSubordinatePageList", params),
  saveSeller: (data) => request.postJson("/api/Seller/SaveSellerChild", data),
  // 设置下属商家加价
  markUp: (params) => request.getAction("/api/Seller/SetMarkUp", params),
  // 获取黑名单列表
  blacklist: () => request.getAction("/api/Seller/GetSellerExcludeMemberList"),
  // 将会员从黑名单移出
  deleteFromBlacklist: (params) =>
    request.getAction("/api/Seller/DeleteSellerExcludeMember", params),
  // 将会员加入黑名单
  addBalcklist: (params) =>
    request.getAction("/api/Seller/AddSellerExcludeMember", params),
  // 获取轮播图列表
  bannerList: () => request.getAction("/api/Seller/GetBannerList"),
  // 删除轮播图
  deleteBanner: (params) =>
    request.getAction("/api/Seller/DeleteBanner", params),
  // 添加轮播图
  saveBanner: (data) => request.postData("/api/Seller/SaveBanner", data),
  // 获取通知公告列表
  noticeList: () => request.getAction("/api/Seller/GetNoticeList"),
  // 删除通知公告
  deleteNotice: (params) =>
    request.getAction("/api/Seller/DeleteNotice", params),
  // 添加通知公告
  saveNotice: (data) => request.postJson("/api/Seller/SaveNotice", data),
};
