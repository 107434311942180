import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 解决报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  { path: "/", redirect: "/dbs/home" },
  {
    path: "/dbs/account/login",
    name: "AccountLogin",
    meta: {
      parent: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AccountLogin.vue"),
  },
  {
    path: "/dbs/main",
    name: "MainPage",
    meta: {
      parent: true,
    },
    component: () => import("../views/MainPage.vue"),
    children: [
      {
        path: "/dbs/home",
        name: "HomePage",
        meta: {},
        component: () => import("../views/HomePage.vue"),
      },
      {
        path: "/dbs/servicer",
        name: "ServicerPage",
        meta: {
          label: "客服",
          icon: "chat-dot-round",
        },
        component: () => import("../views/ServicerPage.vue"),
      },
    ],
  },
  {
    path: "/dbs/h5/index",
    name: "IndexPage",
    component: () => import("../views/tel/index/IndexPage.vue"),
  },
  {
    path: "/dbs/h5/account/login",
    name: "TelAccountLogin",
    component: () => import("../views/tel/AccountLogin.vue"),
  },
  {
    path: "/dbs/h5/task/publish",
    name: "TaskPublish",
    component: () => import("../views/tel/task/TaskPublish.vue"),
  },
  {
    path: "/dbs/h5/shop/manage",
    name: "ShopManage",
    component: () => import("../views/tel/system/ShopManage.vue"),
  },
  {
    path: "/dbs/h5/task/evaluate",
    name: "TaskEvaluate",
    component: () => import("../views/tel/task/TaskEvaluate.vue"),
  },
  {
    path: "/dbs/h5/task/cancel",
    name: "TaskCancel",
    component: () => import("../views/tel/task/TaskCancel.vue"),
  },
  {
    path: "/dbs/h5/product/manage",
    name: "ProductManage",
    component: () => import("../views/tel/system/ProductManage.vue"),
  },
  {
    path: "/dbs/h5/subordinate/seller",
    name: "SubordinateSeller",
    component: () => import("../views/tel/system/SubordinateSeller.vue"),
  },
  {
    path: "/dbs/h5/my/wechat",
    name: "MyWechat",
    component: () => import("../views/tel/payment/MyWechat.vue"),
  },
  {
    path: "/dbs/h5/my/alipay",
    name: "MyAlipay",
    component: () => import("../views/tel/payment/MyAlipay.vue"),
  },
  {
    path: "/dbs/h5/my/email",
    name: "MyEmail",
    component: () => import("../views/tel/system/MyEmail.vue"),
  },
  {
    path: "/dbs/h5/balance/change",
    name: "BalanceChange",
    component: () => import("../views/tel/balance/BalanceChange.vue"),
  },
  {
    path: "/dbs/h5/balance/resale",
    name: "BalanceResale",
    component: () => import("../views/tel/balance/BalanceResale.vue"),
  },
  {
    path: "/dbs/h5/balance/selling",
    name: "BalanceSelling",
    component: () => import("../views/tel/balance/BalanceSelling.vue"),
  },
  {
    path: "/dbs/h5/balance/buy",
    name: "BalanceBuy",
    component: () => import("../views/tel/balance/BalanceBuy.vue"),
  },
  {
    path: "/dbs/h5/seller/tutorial",
    name: "SellerTutorial",
    component: () => import("../views/tel/system/SellerTutorial.vue"),
  },
  {
    path: "/dbs/h5/blacklist/manage",
    name: "BlacklistManage",
    component: () => import("../views/tel/system/BlacklistManage.vue"),
  },
  {
    path: "/dbs/h5/banner/manage",
    name: "BannerManage",
    component: () => import("../views/tel/system/BannerManage.vue"),
  },
  {
    path: "/dbs/h5/notice/manage",
    name: "NoticeManage",
    component: () => import("../views/tel/system/NoticeManage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
