import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/css/common.scss";
import "./assets/icon/iconfont-eye/iconfont.css";

Vue.config.productionTip = false;

import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/element-variables.scss";
Vue.use(ElementUI);

import api from "./api";
Vue.prototype.$api = api;
import utils from "./utils/utils.js";
Vue.prototype.$utils = utils;

Vue.prototype.$bus = new Vue();

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import {
  Toast,
  Field,
  Button,
  Icon,
  Swipe,
  SwipeItem,
  Dialog,
  PullRefresh,
  Image,
  Popup,
  Tabs,
  Tab,
  Picker,
  RadioGroup,
  Radio,
  Stepper,
  DatetimePicker,
  Slider,
  CheckboxGroup,
  Checkbox,
  Switch,
  Uploader,
  Popover,
} from "vant";
Vue.use(Toast)
  .use(Field)
  .use(Button)
  .use(Icon)
  .use(Swipe)
  .use(SwipeItem)
  .use(Dialog)
  .use(PullRefresh)
  .use(Image)
  .use(Popup)
  .use(Tabs)
  .use(Tab)
  .use(Picker)
  .use(RadioGroup)
  .use(Radio)
  .use(Stepper)
  .use(DatetimePicker)
  .use(Slider)
  .use(CheckboxGroup)
  .use(Checkbox)
  .use(Switch)
  .use(Uploader)
  .use(Popover);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
