export const views = [
  {
    name: "TaskPublish",
    path: "/dbs/task/publish/:id",
    component: () => import("../views/task/TaskPublish.vue"),
    meta: {
      label: "发布淘宝任务",
      parentName: "TaskManage",
      parentLabel: "任务管理",
    },
  },
  {
    name: "TaskMain",
    path: "/dbs/task/main",
    component: () => import("../views/task/TaskMain.vue"),
    meta: {
      label: "淘宝主任务",
      parentName: "TaskManage",
      parentLabel: "任务管理",
    },
  },
  {
    name: "TaskChild",
    path: "/dbs/task/child",
    component: () => import("../views/task/TaskChild.vue"),
    meta: {
      label: "任务明细",
      parentName: "TaskManage",
      parentLabel: "任务管理",
    },
  },
  {
    name: "TaskRefund",
    path: "/dbs/task/refund",
    component: () => import("../views/task/TaskRefund.vue"),
    meta: {
      label: "待返款任务",
      parentName: "TaskManage",
      parentLabel: "任务管理",
    },
  },
  {
    name: "TaskRefunded",
    path: "/dbs/task/refunded",
    component: () => import("../views/task/TaskRefunded.vue"),
    meta: {
      label: "已返款任务",
      parentName: "TaskManage",
      parentLabel: "任务管理",
    },
  },
  {
    name: "TaskEvaluate",
    path: "/dbs/task/evaluate",
    component: () => import("../views/task/TaskEvaluate.vue"),
    meta: {
      label: "评价任务",
      parentName: "TaskManage",
      parentLabel: "任务管理",
    },
  },
  // {
  //   name: "TaskUnfinished",
  //   path: "/dbs/task/unfinished",
  //   component: () => import("../views/task/TaskUnfinished.vue"),
  //   meta: {
  //     label: "待完结子任务",
  //     parentName: "TaskManage",
  //     parentLabel: "任务管理",
  //   },
  // },
  {
    name: "TaskCancel",
    path: "/dbs/task/cancel",
    component: () => import("../views/task/TaskCancel.vue"),
    meta: {
      label: "淘宝任务取消记录",
      parentName: "TaskManage",
      parentLabel: "任务管理",
    },
  },
  {
    name: "ShopManage",
    path: "/dbs/shop/manage",
    component: () => import("../views/system/ShopManage.vue"),
    meta: {
      label: "淘宝店铺管理",
      parentName: "SystemManage",
      parentLabel: "系统管理",
    },
  },
  {
    name: "ProductManage",
    path: "/dbs/product/manage",
    component: () => import("../views/system/ProductManage.vue"),
    meta: {
      label: "淘宝商品管理",
      parentName: "SystemManage",
      parentLabel: "系统管理",
    },
  },
  {
    name: "SubordinateSeller",
    path: "/dbs/subordinate/seller",
    component: () => import("../views/system/SubordinateSeller.vue"),
    meta: {
      label: "下属商家",
      parentName: "SystemManage",
      parentLabel: "系统管理",
      auth: ["isAgent", "hasChildSeller"],
    },
  },
  {
    name: "MarkupProfit",
    path: "/dbs/markup/profit",
    component: () => import("../views/system/MarkupProfit.vue"),
    meta: {
      label: "任务加价收益",
      parentName: "SystemManage",
      parentLabel: "系统管理",
      auth: ["isAgent", "hasChildSeller"],
    },
  },
  {
    name: "BlacklistManage",
    path: "/dbs/blacklist/manage",
    component: () => import("../views/system/BlacklistManage.vue"),
    meta: {
      label: "黑名单管理",
      parentName: "SystemManage",
      parentLabel: "系统管理",
    },
  },
  {
    name: "BannerManage",
    path: "/dbs/banner/manage",
    component: () => import("../views/system/BannerManage.vue"),
    meta: {
      label: "轮播图管理",
      parentName: "SystemManage",
      parentLabel: "系统管理",
      auth: ["isAgent"],
    },
  },
  {
    name: "NoticeManage",
    path: "/dbs/notice/manage",
    component: () => import("../views/system/NoticeManage.vue"),
    meta: {
      label: "通知公告管理",
      parentName: "SystemManage",
      parentLabel: "系统管理",
      auth: ["isAgent"],
    },
  },
  {
    name: "SellerTutorial",
    path: "/dbs/seller/tutorial",
    component: () => import("../views/system/SellerTutorial.vue"),
    meta: {
      label: "商家教程",
      parentName: "SystemManage",
      parentLabel: "系统管理",
    },
  },
  {
    name: "BalanceChange",
    path: "/dbs/balance/change",
    component: () => import("../views/balance/BalanceChange.vue"),
    meta: {
      label: "余额变更记录",
      parentName: "BalanceManage",
      parentLabel: "余额管理",
      secondParentName: "PersonRecord",
      secondParentLabel: "个人记录",
    },
  },
  {
    name: "BalanceResale",
    path: "/dbs/balance/resale",
    component: () => import("../views/balance/BalanceResale.vue"),
    meta: {
      label: "余额转售记录",
      parentName: "BalanceManage",
      parentLabel: "余额管理",
      secondParentName: "PersonRecord",
      secondParentLabel: "个人记录",
    },
  },
  {
    name: "BalanceSelling",
    path: "/dbs/balance/selling",
    component: () => import("../views/balance/BalanceSelling.vue"),
    meta: {
      label: "余额待售列表",
      parentName: "BalanceManage",
      parentLabel: "余额管理",
      secondParentName: "BuyRecharge",
      secondParentLabel: "购买充值",
      tip: "购买待售订单充值，支持微信/支付宝/银行卡！",
    },
  },
  {
    name: "BalanceBuy",
    path: "/dbs/balance/buy",
    component: () => import("../views/balance/BalanceBuy.vue"),
    meta: {
      label: "余额购买列表",
      parentName: "BalanceManage",
      parentLabel: "余额管理",
      secondParentName: "BuyRecharge",
      secondParentLabel: "购买充值",
      tip: "请在本列表完成付款，充值金额实时到账！",
    },
  },
];
